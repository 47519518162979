const Config = {
//api_url: 'https://payroll.peoplehrm.in'
 //API_URL: 'https://apidev.ebusso.com',
 API_URL: 'https://api.ebusso.com',

 //BASE_URL : 'http://localhost:3000/react',

 BASE_URL : 'https://daninternational.rameesystems.com/react',

 
 
 //BASE_URL : 'https://demo.ebusso.com/react',

 //BASE_URL : 'https://aytac.rameesystems.com/react',

 //BASE_URL : 'https://kmfood.co.uk/react',

 

 DEMO_URL : 'https://demo.ebusso.com',
 LIVE_URL : 'https://api.ebusso.com',

 //LOG_OUT : 'https://aytac.rameesystems.com',

// LOG_OUT : 'https://kmfood.co.uk',

 LOG_OUT : 'https://daninternational.rameesystems.com',
};
  
export default Config;
  
 