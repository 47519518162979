import React, { useState, useEffect, useRef, useCallback  } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import config from "../common/Config";
import AccountService from '../../services/AccountServices';
import Swal from 'sweetalert2';

const PaymentAllocation = (props) =>{

	const { customerId } = useParams();

	

	return(
		<>
			<Header />
			<div className="breadcrumbs">
	          	<div className="container-fluid">
		            <div className="row">
		                <div className="col-12 col-lg-12 col-sm-12"> 
		                  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
		                  <Link to="/account/customer_accounts">Account</Link> 
		                  <Link to={`/payments/payment_allocation/${customerId}`}>Payment Allocation {customerId} </Link> 
		                </div>
		            </div>
	          	</div>
	      	</div>

			<Footer />
		</>

	);
}
export default PaymentAllocation;