import React from 'react';
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './components/About';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import NewSales from './components/NewSales';
import OrderHistory from './components/OrderHistory';
import CreateManifest from './components/CreateManifest';
import DataTable from './components/DataTable';
import ManifestList from './components/ManifestList';
//import MarkDelivery from './components/MarkDelivery';
import NewMarkDelivery from './components/NewMarkDelivery';
import OpenTrips from './components/OpenTrips';
import OpenTripSummary from './components/OpenTripSummary';
import CompleteTrips from './components/CompleteTrips';
import CloseTripSummary from './components/CloseTripSummary';
import ReturnList from './components/Return/ReturnList';
import SalesView from './components/SalesView';
//picking start
import PickingCreate from './components/picking/PickingCreate';
import PickingList from './components/picking/PickingList';
import EditPicking from './components/picking/EditPicking';
import PickingHistory from './components/picking/PickingHistory';
import ViewPicking from './components/picking/ViewPicking';
import ShortQty from './components/picking/ShortQty';
import ViewShortQty from './components/picking/ViewShortQty';
import ConfirmShortQty from './components/picking/ConfirmShortQty';
import Proforma from './components/picking/Proforma';
import ProformaInvoice from './components/picking/ProformaInvoice';
import ViewPickingList  from './components/picking/ViewPickingList';
//picking End
import DateRangePicker from './components/DateRangePicker';
import Edit_New_Invoice from './components/Edit_New_Invoice';
import AddReturn from './components/Return/AddReturn';
import RoutePlaningInvoice from './components/RoutePlaningInvoice';
// import CloseTripSummery from './components/ClosedTripSummary';
import ClosedTripSummary from './components/ClosedTripSummary';
import ModalComponent from './components/ModalComponent';
import TableComponent from './components/TableComponent';
import Products from './components/Master/Products/Products';
import Add_Products from './components/Master/Products/Add_Products';
import Edit_Product from './components/Master/Products/Edit_Product';
import Previous_Product from './components/Master/Products/PreviousProduct';
import Supplier from './components/Master/Supplier/Supplier';
import Add_Supplier from './components/Master/Supplier/Add_Supplier';
import Edit_Supplier from './components/Master/Supplier/Edit_Supplier';
import Customers from './components/Master/Customers/Customers';
import Edit_Customer from './components/Master/Customers/Edit_Customer';
import Contra_Price from './components/Master/Customers/ContraPrice';
import ContraList from './components/Master/Customers/ContraList';
import Contra_Price_Edit from './components/Master/Customers/Contra_Price_Edit';
import Users from './components/Master/Users/Users';
import User_Profile from './components/Master/Users/User_Profile';
import Brands from './components/Master/Brands/Brands';
import Units from './components/Master/Units/Units';
import Product_Category from './components/Master/Product_Category';
import Products_Price_Variation from './components/Master/PriceVariation/Products_Price_Variation';
import Customer_Credit_Facility from './components/Master/CustomerCredit/Customer_Credit_Facility';
// import Catalogue from './components/Master/Catalogue';
import CustomerNotOrderReport from './components/reports/CustomerNotOrderReport';
import MisReport from './components/reports/MisReport';
import InventorySalesReport from './components/reports/InventorySalesReport';
import CrossOffReport from './components/reports/CrossOffReport';
import SalesRepReport from './components/reports/SalesRepReport';
import ProfitLossReport from './components/reports/ProfitLossReport';
import NewRSales from './components/nsales/NewRSales';
import SalesOrderHistory from './components/nsales/SalesOrderHistory';
import EditSalesInvoice  from './components/nsales/EditSalesInvoice';
import RejectSales      from './components/nsales/RejectSales';
import SummaryPrint from './components/trip/SummaryPrint';
import OrderSummaryPrint from './components/nsales/OrderSummaryPrint';

import monthsales from './components/dashboard/MonthSalesList';
import CreateNewManifest from './components/dispatch/CreateNewManifest';
import CreateManifestPrint from './components/dispatch/CreateManifestPrint';
import { StockTakeHistory, StockTakeHistoryList, StockTakeList, StartStockTake } from './components/stockTake';
import { PurchasesOrder, PurchasesHistory, PurchasesAddFrom, EditPurchasesFrom, PurchaseViewPrint, AddPurchasesGRN, PurchaseHistoryViewPrint } from './components/purchases';
import { PushSage, AllPushInvoice, PaymentProcess, CustomerAccounts, SupplierLedger, PaymentLadger, MyLadger, ProductLadger, ProductLadgerView, PaymentAllocation } from './components/account';
import Promos from './components/Promotions/Promos';
import Add_Promos from './components/Promotions/Add_Promos';
import Edit_Promos from './components/Promotions/Edit_Promos';
import Slider_settings from './components/slider_settings/Slider_settings';
import Catalogue from './components/Catalogue/Catalogue';
import Epos from './components/sales/Epos';
import EposSales from './components/sales/EposSales';


import ReturnListPrint from './components/Return/ReturnListPrint';
// import Finish_Pay from './components/sales/Finish_Pay';
// import Incomplete_Order from './components/sales/Incomplete_Order';

import NewSalesDashbard from './components/dashboard/NewSales';
import SalesOrderDashbard from './components/dashboard/SaleOrder';
import DeliveredInvoice from './components/dashboard/DeliveredInvoice';
import OrderAccepted from './components/dashboard/OrderAccepted';
import PicklistGenerated from './components/dashboard/PicklistGenerated';
import PickingConfirm from './components/dashboard/PickingConfirm';
import TopSales from './components/dashboard/TopSales';
import MaxValueOrder from './components/dashboard/MaxValueOrder';
import ManifestGenerated from './components/dashboard/ManifestGenerated';
import DeliveryConfirmed from './components/dashboard/DeliveryConfirmed';



import OrderLocationReport from './components/reports/OrderLocationReport';


import GroupPermissions from  './components/Settings/GroupPermissions';
import ChangePermissions from  './components/Settings/ChangePermissions';

// The basename is set to '/react' for deployment to a subdirectory
function App() {
  return (
    <Router basename="/react">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/DataTable" element={<DataTable />} />
        <Route path="/login" element={<Login />} />      
        <Route path="/" element={<Login />} />
        <Route path="/About" element={<About />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/:prefix/:userId" element={<Dashboard />} />
        <Route path="/new_sales" element={<NewSales />} />
        <Route path="/order_history" element={<OrderHistory />} />
        <Route path="/create_manifest" element={<CreateManifest />} />
        <Route path="/route_planing_invoice" element={<RoutePlaningInvoice />} />
        <Route path="/manifest_list" element={<ManifestList />} />
        <Route path="/manifest_list/mark_delivery/:manifestId" element={<NewMarkDelivery />} />
        <Route path="/demoModal/:manifestId" element={<ModalComponent />} />
        <Route path="/open_trips" element={<OpenTrips />} />
        <Route path="/open_trips/trip_summary/:tripId" element={<OpenTripSummary />} />
        <Route path="/complete_trips" element={<CompleteTrips />} />
        <Route path="/complete_trips/closed_trip_summary/:tripId" element={<CloseTripSummary />} />
        <Route path="/closed_trip_summary" element={<ClosedTripSummary />} />
        <Route path="/return_list" element={<ReturnList />} />
        <Route path="/sales_view" element={<SalesView />} />
        <Route path="/picking/picking_create" element={<PickingCreate />} />
        <Route path="/picking/picking_list" element={<PickingList />} />
        <Route path="/picking/edit_picking/:picklistNo" element={<EditPicking />} />
        <Route path="/picking/picking_history" element={<PickingHistory />} />
        <Route path="/picking/short_qty" element={<ShortQty />} />
        <Route path="/picking/view_short_qty/:picklistNo" element={<ViewShortQty />} />
        <Route path="/picking/confirm_short_qty/:picklistNo/:productId/:orderType" element={<ConfirmShortQty />} />        
        <Route path="/picking/proforma" element={<Proforma />} />
        <Route path="/picking/view_picking/:picklistNo" element={<ViewPicking />} />
        <Route path="/sales/view_picking_list/:picklistNo" element={<ViewPickingList />} />
        <Route path="/DateRangePicker" element={<DateRangePicker />} />
        <Route path="/proforma_invoice" element={<ProformaInvoice />} />
        <Route path="/edit_new_invoice" element={<Edit_New_Invoice />} />
        <Route path="/return/add_return" element={<AddReturn />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/add_product" element={<Add_Products />} />
        <Route path="/products/edit/:productId" element={<Edit_Product />} />
        <Route path="/products/previous_pricing/:productId" element={<Previous_Product />} />
        <Route path="/supplier" element={<Supplier />} />
        <Route path="/supplier/add_supplier" element={<Add_Supplier />} />
        <Route path="/supplier/edit_supplier/:supplierId" element={<Edit_Supplier />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/ContraList" element={<ContraList />} />
        <Route path="/customer/edit_customer/:customerId" element={<Edit_Customer />} />
        <Route path="/customer/contra_price/:customerId" element={<Contra_Price />} />
        <Route path="/customers/contra_price_edit/:customerId" element={<Contra_Price_Edit />} />
        <Route path="/users" element={<Users />} />
        <Route path="/user/edit_profile/:userId" element={<User_Profile />} />
        <Route path="/Brands" element={<Brands />} />
        <Route path="/Units" element={<Units />} />
        <Route path="/product_category" element={<Product_Category />} />
        <Route path="/products_price_variation" element={<Products_Price_Variation />} />
        <Route path="/Customer_Credit_Facility" element={<Customer_Credit_Facility />} />
        {/* <Route path="/catalogue" element={<Catalogue />} /> */}
        <Route path="/testTable" element={<TableComponent />} />
        <Route path="reports/customer_not_order_report" element={<CustomerNotOrderReport />} />
        <Route path="reports/mis_report" element={<MisReport />} />
        <Route path="reports/inventory_sales_report" element={<InventorySalesReport />} />
        <Route path="reports/missing_order" element={<CrossOffReport />} />
        <Route path="reports/sales_report" element={<SalesRepReport />} />
        <Route path="reports/profit_loss_report" element={<ProfitLossReport />} />
        <Route path="sales/newsales" element={<NewRSales />} />
        <Route path="sales/shistory" element={<SalesOrderHistory />} />
        <Route path="sales/reject_sales" element={<RejectSales />} />
        <Route path="/sales/edit_invoice/:orderId" element={<EditSalesInvoice />} />
        <Route path="/trip_summary/print/:orderId" element={<SummaryPrint />} />
        <Route path="/reports/mis_report/print/:orderId" element={<SummaryPrint />} />
        <Route path="/picking/picking_list/view/:orderId" element={<SummaryPrint />} />
        <Route path="order_summary/print/:orderId" element={<OrderSummaryPrint />} />
        <Route path="/monthsales" element={<monthsales />} />
        <Route path="delivery/route_planing" element={<CreateNewManifest />} />
         <Route path="/delivery/route_planing/view/:orderId" element={<CreateManifestPrint />} />
        <Route path="stock_take/history" element={<StockTakeHistory />} />
        <Route path="/products/show_history_of_stock_take_list/:stockId" element={<StockTakeHistoryList />} />
        <Route path="stock_take/stock_take_list" element={<StockTakeList />} />
        <Route path="stock_take/start_stock_take" element={<StartStockTake />} />
        <Route path="purchases/purchases_order" element={<PurchasesOrder />} />
        <Route path="purchases/purchases_grn" element={<PurchasesHistory />} />
        <Route path="purchases/add" element={<PurchasesAddFrom />} />
        <Route path="/purchases/edit_po/:orderId" element={<EditPurchasesFrom />} />
        <Route path="/purchases/view/:orderId" element={<PurchaseViewPrint />} />
        <Route path="/purchases/view_grn/:orderId" element={<PurchaseHistoryViewPrint />} />
        <Route path="/purchases/add_grn/:orderId" element={<AddPurchasesGRN />} />
        <Route path="account/push_sage" element={<PushSage />} />
        <Route path="account/push_invoice" element={<AllPushInvoice />} />
        <Route path="account/payments" element={<PaymentProcess />} />
        <Route path="account/customer_accounts" element={<CustomerAccounts />} />
        <Route path="account/supplier_ledger" element={<SupplierLedger />} />
        <Route path="account/payment_ladger" element={<PaymentLadger />} />
        <Route path="account/my_ledger" element={<MyLadger />} />
        <Route path="Promotions/Promos" element={<Promos />} />
        <Route path="/Promotions/Add_Promos" element={<Add_Promos />} />
        <Route path="/Promotions/Edit_Promos" element={<Edit_Promos />} />
        <Route path="slider_settings" element={<Slider_settings />} />
        <Route path="/Promotions/Edit_Promos/:id" element={<Edit_Promos />} />
        <Route path="Catalogue" element={<Catalogue />} />
        <Route path="sales/Epos" element={<Epos />} />
        <Route path="sales/epos_sales" element={<EposSales />} />
        <Route path="dashboard/NewSales" element={<NewSalesDashbard />} />
        <Route path="dashboard/DeliveredInvoice" element={<DeliveredInvoice />} />
        <Route path="dashboard/SalesOrder" element={<SalesOrderDashbard />} />
        <Route path="dashboard/OrderAccepted" element={<OrderAccepted />} />
        <Route path="dashboard/picklistGenerated" element={<PicklistGenerated />} />
        <Route path="dashboard/PickingConfirm" element={<PickingConfirm />} />
        <Route path="dashboard/TopSales" element={<TopSales />} />
        <Route path="dashboard/MaxValueOrder" element={<MaxValueOrder />} />
        <Route path="dashboard/ManifestGenerated" element={<ManifestGenerated />} />
        <Route path="dashboard/DeliveryConfirmed" element={<DeliveryConfirmed />} />


        <Route path="account/product_ledger" element={<ProductLadger />} />
        <Route path="payments/view_product_ledger/:productId/:startDate/:endDate/:orderType" element={<ProductLadgerView />} />

        {/* <Route path="sales/Finish_Pay" element={<Finish_Pay />} />
        <Route path="sales/Incomplete_Order" element={<Incomplete_Order />} /> */}


        <Route path="/returns/view/:orderId" element={<ReturnListPrint />} />

        <Route path="/reports/lat_long_report" element={<OrderLocationReport />} />


        <Route path="/settings/group_permissions" element={<GroupPermissions />} />
        <Route path="/settings/change_permissions/:roleId" element={<ChangePermissions />} />


        <Route path="/payments/payment_allocation/:customerId" element={<PaymentAllocation />} />

        


        
        
      </Routes>
    </Router>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
 // <React.StrictMode>
    <App />
 // </React.StrictMode>
);
